import { Button } from "component/shared/Button/Button";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./SubscriptionPlanNewVersion.module.css";
export default function SalesforceNewVersion() {
  const { t } = useTranslation();
  function redirectToV2Setting() {
    window.location.href = `https://${process.env.REACT_APP_V2_PATH}/integrations`;
  }
  return (
    <div className={`content no-scrollbars`}>
      <div className={`_content ${styles.contentContainer}`}>
        <div className={styles.container}>
          <div className={styles.title}>
            {t("account.salsesforceNewVersion.title")}
          </div>
          <div className={styles.group}>
            <div className={styles.content}>
              <Trans
                i18nKey={"account.salsesforceNewVersion.description"}
                values={{ name: "Salesforce" }}
              >
                A new version of Salesforce is now available. Please click the
                button below to switch to the latest version and enjoy a more
                streamlined user experience with enhanced capabilities in
                SleekFlow 2.0. For any questions or issues during the
                transition, please visit our
                <a href="https://help.sleekflow.io/en_US/salesforce-integration">
                  Help Center
                </a>
                for more details or contact our support team.
              </Trans>
            </div>
          </div>
          <div className={styles.action}>
            <Button primary onClick={redirectToV2Setting}>
              {t("account.salsesforceNewVersion.button.redirect")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
